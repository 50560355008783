<template>
  <div style="min-width: 1360px">
    <Header/>
    <headerBanner :content="bannerContent" title="CRM客户关系管理系统" @btnClick="bannerClick" imgStyle="height:0%;height:0"
    :price="bannerPrice" contentStyle="width:100%" :bodyStyle="bodyStyle"/>
    <div class="card">
      <div class="card-container">
        <div style="margin-bottom: 40px;font-size: 30px;font-weight: bold;">CRM为什么如此重要</div>
        <div style="margin-left: -15px;position: relative;left: 15px;">
          <div class="crm-card" v-for="(item,i) in dataList1" :key="i">
            <div style="font-size: 18px;font-weight: bold;color: #333;">
              {{ item.title }}<span style="color: #1935DE;">{{ item.blueTxt }}</span>
            </div>
            <div style="margin-top: 20px;font-size: 16px;line-height: 1.4;text-align: justify;">{{ item.content }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="card" style="background-color: #F7F8FE;">
      <div class="card-container">
        <div style="font-size: 30px;font-weight: bold;color: #333;margin-bottom: 30px;">CRM是如何发挥作用的？</div>
        <div style="display: flex;align-items: center;justify-content: space-between;">
          <div class="card-cell" v-for="(item,i) in dataList2" :key="i">
            <div style="background-color: #1935DE;height: 50px;text-align: center;line-height: 50px;font-size: 18px;font-weight: bold;color: #fff;">
              {{ item.title }}
            </div>
            <div style="padding: 20px;font-size: 16px;line-height: 1.5;text-align: justify;">{{ item.content }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-content">
      <div class="card-title">为什么要使用CRM</div>
      <div class="erp-container crmBox">
        <div class="crmBox-card" v-for="(item,i) in crmList" :key="i">
          <div class="crmBox-card-title">{{ item.title }}</div>
          <div style="display: flex;align-items: flex-end;">
            <div class="crmBox-card-sub">{{ item.subheading }}</div>
            <i style="font-size: 30px;font-weight: bold;" class="el-icon-top"></i>
          </div>
          <div class="crmBox-line"></div>
          <div class="crmBox-content">{{ item.content }}</div>
        </div>
      </div>
    </div>
    <div class="card" style="background-color: #F7F8FE;">
      <div class="card-container">
        <div style="font-size: 30px;font-weight: bold;margin-bottom: 30px;">不但功能强大 还免费迭代</div>
        <div style="display: flex;height: 500px;">
          <div class="left-menu">
            <div class="left-menu-cell" v-for="(item,i) in dataList3" :key="i">
              <div class="left-menu-cell-box" :class="{isActive:data3Index == i}" @click="data3Index = i">
                {{ item.title }}
              </div>
            </div>
          </div>
          <div style="flex: 1;width: 500px;background-color: #fff;padding-left: 40px;padding-top: 30px;box-sizing: border-box;padding-right: 40px;">
            <div style="font-size: 18px;font-weight: bold;">{{ children.title }}</div>
            <div style="font-size: 15px;color: #666;margin-top: 15px;padding-bottom: 28px;border-bottom: 1px dashed #48528B;">
              {{ children.tips }}
            </div>
            <div style="margin-left: -20px;margin-top: 30px;position: relative;left: 20px;">
              <div class="menu-item-card" v-for="(row,index) in children.dataList" :key="index">
                <div style="display: flex;align-items: center;font-size: 18px;font-weight: bold;">
                  <span style="color: #1935DE;font-size: 30px;margin-right: 10px;">◆</span> {{ row.title }}
                </div>
                <div style="font-size: 15px;color: #333;">{{ row.content }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <applyTrial ref="dialog" :servicePlatform="'CRM客户关系管理系统'"/>
    <Footer/>
  </div>
</template>
<script>
import Header from "@/views/home/newCloudeHeader.vue";
import applyTrial from "@/components/assembly/applyTrial";
import Footer from "@/views/home/newCloudeFooter.vue";
import headerBanner from '@/components/assembly/headerBanner';
export default {
  components: {Header, Footer, headerBanner, applyTrial},
  computed: {
    children() {
      return this.dataList3[this.data3Index]
    }
  },
  data() {
    return {
      bannerPrice:'市场价格：160000元',
      bannerContent: "帮助企业获得客户，通过多种渠道收集和管理客户信息，实现对客户需求的及时响应和满足。帮助企业管理客户，通过建立客户档案、跟踪客户行为、分析客户价值等方式，实现对客户的全面了解和分类",
      bodyStyle: `background-image:url(${require('@/assets/img/home/crmBanner.png')});background-size: 390px;background-repeat: no-repeat;background-position: right 25px;`,
      data3Index: 0,
      dataList1: [
        {
          title: '现有客户',
          blueTxt: '核心化',
          content: 'CRM可全方位归集客户跟进、成交、交付、服务、回款等信息，帮助营销人员落地差异化的客户服务，盘活存量客户、获取更多客户，真正提升客户服务与客户价值。',
        },
        {
          title: '客户服务',
          blueTxt: '全员化',
          content: '客户对产品和服务质量都相当敏感，任何差池都可能导致客源流失。因此能否及时回应客户的反馈至关重要。CRM能够将所有客户回信存储在特定位置，从客服到营销的任何成员都有权访问并解决客户痛点和诉求。',
        },
        {
          title: '团队组织',
          blueTxt: '体系化',
          content: '在 CRM 系统中，团队内部的所有交互细节都将被记录，沟通信息不再分散储存。 CRM 还可以提醒您需要完成的待处理任务，再也不用担心忘记代办事项导致的进度耽误。CRM还便于随时随地访问，不再受困于纸质材料存储查阅的复杂性。',
        },
        {
          title: '日常任务',
          blueTxt: '自动化',
          content: '在权责交互的职场中，一项任务往往受制于其他相关任务。原始的填写表格、递交报告、法务咨询占用了员工大量精力。但如果将细节工作交由CRM实现云化，销售代表就可以专注于业务水平的提升。',
        },
        {
          title: '员工责任',
          blueTxt: '清晰化',
          content: '在企业发展壮大的过程中，客户也会同步增加。但如果一切工作都依赖人工，出现失误和遗漏的可能性很大。但是如果利用好 CRM，管理者就可以分配职责，并且追踪进展。当业务的进度实现透明，就有助于提高员工推进的积极性，更好达成销售目标。',
        }
      ],
      dataList2: [
        {
          title: '提高营销效率',
          content: '全渠道获客、潜客识别评估、线索培育转化、渠道ROI分析、数据驾驶舱等CRM系统功能可助力企业营销推广获客，溯源转化效果，驱动业绩增长。'
        },
        {
          title: ' 提升销售业绩',
          content: '通过线索的精细化管理、全方位的客户360°画像、标准化商机销售流程与预测以及灵活的产品、价格、促销返利、订单管理等功能，实现线索到现金的完整业绩闭环，把握每一次成交机会，驱动业绩增长，提升客户满意度。'
        },
        {
          title: "提升客户满意度",
          content: "通过以工单全流程为核心，贯通企业内部组织，连接下游服务商，为终端用户提供在线服务与现场服务，且无缝衔接CRM售前、售中业务，真正实现服务全流程数字化管理，为客户提供高效、专业的服务体验。"
        },
        {
          title: "提高协同效率",
          content: "客群结合CRM业务，实现以客户为中心的团队协作、提升销售、渠道打单的业务效率；分享、日志、审批、日程等轻量级办公场景关联CRM，提升办公效率。"
        }
      ],
      crmList: [
        {title: 'ROI提升', subheading: '80%', content: '快速高效率在不同渠道获取线索、监控并提升你的广告ROI。'},
        {title: '效率提升', subheading: '30%', content: '借助CRM为销售提供的各种工具，提高销售工作效率，加速客户成交过程。'},
        {title: '满意度提升', subheading: '50%', content: '高效专业的在线服务和现场服务体系，帮助企业提升客户满意度，产生更多复购订单。'},
        {title: '业绩增长', subheading: '50%', content: '业务全流程数字化，全方位提升营销、销售、服务、财务、管理等各方面的效率，智能分析帮你不断洞察业务增长点，最终获得可持续的健康增长。'}
      ],
      dataList3: [
        {
          title: '销售自动化',
          tips: '从线索到订单，自动化处理琐碎的销售流程，让销售人员集中精力创建并跟进商机，大幅提升销售业绩，发展更长久的客户关系。',
          dataList: [
            {
              title: '线索管理',
              content: '海量线索创建、培育、评分、转化、分发',
            },
            {
              title: '商机管理',
              content: '识别并跟踪销售机会，促进快速成单',
            },
            {
              title: '客户管理',
              content: '统一客户资源，建立360°客户视图',
            },
            {
              title: '联系人管理',
              content: '与每一位联系人紧密互动，建立持久的客户关系',
            },
            {
              title: "工作流自动化",
              content: "自动处理日常任务，为销售人员节省时间去做更有价值的工作"
            },
            {
              title: "销售预测",
              content: "根据当前销售趋势梳理数据，进行精准的销售预测"
            },
            {
              title: "数据分析",
              content: "快速生成报表和仪表板，全面了解业务运行情况，依据数据明智决策"
            },
            {
              title: "移动应用",
              content: "行走在路上，工作在云端。随时随地使用系统并与团队协同，推动成交"
            }
          ]
        },
        {
          title: '销售流程管理',
          tips: '根据有效的销售策略建立标准化销售流程，为销售团队制定统一的成功路线图。确保团队每个人都遵循正确的销售流程，整体提升销售团队的业绩达成率。',
          dataList: [
            {
              title: '绘制蓝图',
              content: '构建并实施统一的销售流程',
            },
            {
              title: '多页面布局',
              content: '为不同的部门创建不同的业务流程',
            },
            {
              title: '审批流程',
              content: '根据企业内部需求，构建严谨的审批流程',
            },
            {
              title: '线索自动分配',
              content: '根据条件筛选线索，自动分配给销售人员',
            },
            {
              title: "升级规则",
              content: "自动对逾期的服务支持工单进行升级，塑造满意的客户"
            },
            {
              title: "验证规则",
              content: "保证CRM系统中数据的准确性和完整性"
            },
          ]
        },
        {
          title: '销售绩效管理',
          tips: '数字是衡量业绩的标准。销售绩效管理有助于清楚了解当前业绩，预测未来潜力，成功应对不断变化的市场环境。',
          dataList: [
            {
              title: '销售预测',
              content: '分配销售目标，跟踪销售完成进度，预测销售收入',
            },
            {
              title: '区域管理',
              content: '对客户进行细分，分配销售资源，了解不同区域的产出',
            },
            {
              title: '游戏化竞争',
              content: '游戏化销售激励方式，鼓舞团队士气，实现自我驱动',
            },
            {
              title: '销售漏斗',
              content: '分析销售活动，全面跟踪每个阶段的商机数量，评估流程合理性',
            }
          ]
        },
        {
          title: '营销自动化',
          tips: '自动化市场营销平台，高效建立客户资源池，提供优质商机，有效提升市场投入产出比。',
          dataList: [
            {
              title: '个性化营销方案',
              content: '对客户进行细分，分别制定并执行不同的营销策略',
            },
            {
              title: '自动化线索培育',
              content: '自动化线索培育过程，高质量互动，加速线索转化',
            },
            {
              title: '市场活动',
              content: '轻松邀约，分析转化效果，评估线上线下活动的投入产出比',
            },
            {
              title: '表单信息收集',
              content: '拖拽方式创建Wed表单，高效捕获网站访客信息',
            },
            {
              title: "名片扫描",
              content: "纸质名片自动导入CRM，不丢失，易跟进"
            },
            {
              title: "邮件营销",
              content: "面向特定客户一键群发个性化邮件，加强潜客互动"
            },
            {
              title: "营销归因",
              content: "综合分析销售和营销数据，找出投资回报好的营销活动"
            }
          ]
        },
        {
          title: '全渠道沟通',
          tips: '提供多种方式触达到客户或潜在客户，集中管理来自所有渠道的客户信息，更好地与客户开展互动，帮助团队加强对客户的了解。',
          dataList: [
            {
              title: '邮件',
              content: '通过邮件与客户往来沟通，帮助销售人员提升销售额',
            },
            {
              title: '电话',
              content: '直接从CRM里拨打/接听电话，根据商机衡量电话效果',
            },
            {
              title: '在线聊天',
              content: '在网页中嵌入在线聊天工具，访客及时转化为潜客',
            },
            {
              title: '网络会议',
              content: '将客户信息和网络会议连接得更紧密',
            },
            {
              title: "实时提醒",
              content: "为销售人员及时推送客户动态，不错过任何客户消息"
            },
            {
              title: "自助式销售",
              content: "客户自助服务平台，客户自助下单，销售周期更短"
            },

          ]
        },
        {
          title: 'BI商业智能',
          tips: '无技术门槛，根据需求灵活个性化定制，对系统数据进行全面立体分析。执行层依据数据了解工作成果并调整自己的工作策略；管理层基于数据可看清现有业务正在面临的瓶颈，预估未来趋势，让数据来驱动决策。',
          dataList: [
            {
              title: '报表',
              content: '轻松展示销售趋势、营销成果、绩效等关键业绩指标，与团队共享',
            },
            {
              title: '图表/仪表板',
              content: '可视化呈现数据和趋势，更直观更清晰地建立业务洞察',
            },
            {
              title: 'KPI图表',
              content: '聚焦达成业绩的关键指标，快速给予改进和提升',
            },
            {
              title: '异常检测器',
              content: '基于历史数据检测偏差，预知未来业务异常，未雨绸缪',
            },
            {
              title: "比较工具",
              content: "自定义条件进行多维度比较，在对比中发现问题并改进"
            },
            {
              title: "目标监控仪表板",
              content: "清晰的目标仪表板，帮助团队实现目标，更要超越目标"
            },
            {
              title: "漏斗图",
              content: "全面管控销售管道，了解各阶段丢失的客户"
            }
          ]
        },
        {
          title: '个性化定制',
          tips: '以搭积木的方式灵活定制您的个性化CRM系统，高度贴合您的实际业务，让您拥有优秀的使用体验。随需而动易扩展，系统可伴随您的企业共同成长，规避更换系统造成的各项成本。',
          dataList: [
            {
              title: '多页面布局',
              content: '适合复杂业务的企业，不同业务模式展现不同的页面',
            },
            {
              title: '自定义组件',
              content: '自由添加您所需要的模块、字段、按钮',
            },
            {
              title: '视图和过滤器',
              content: '筛选数据，快速查找或访问所需的数据',
            },
            {
              title: '自定义函数',
              content: '处理复杂的业务流程',
            }
          ]
        },
      ]
    }
  },
  methods: {
    bannerClick() {
      this.$refs.dialog.show = true;
    }
  }
}
</script>
<style lang="scss" scoped>
.card {
  padding: 60px 0;
  &-container {
    width: 1200px;
    margin: auto;
  }
}
.crm-card {
  width: 390px;
  height: 230px;
  border: 1px solid #ddd;
  margin-bottom: 14px;
  display: inline-block;
  margin-right: 15px;
  box-sizing: border-box;
  padding: 20px;
  vertical-align: middle;
}
.card-cell {
  width: 290px;
  height: 250px;
  background-color: #fff;
}
.page-content {
  padding: 60px 0;
}
.card-title {
  width: 1200px;
  margin: auto;
  font-size: 30px;
  font-weight: bold;
}
.erp-container {
  width: 1200px;
  margin: auto;
  padding-top: 40px;
  .left {
    width: 800px;
  }
}
.crmBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &-card {
    width: 290px;
    height: 320px;
    box-sizing: border-box;
    padding: 30px 20px;
    color: #333;
    &-title {
      font-size: 24px;
      margin-bottom: 10px;
    }
    &-sub {
      font-size: 30px;
      font-weight: bold;
    }
    .crmBox-line {
      width: 100%;
      height: 0;
      border: 1px dashed #fff;
      margin: 30px 0;
    }
    .crmBox-content {
      font-size: 16px;
      line-height: 1.6;
    }
    &:nth-child(1) {
      background-color: #D3D3C7;
    }
    &:nth-child(2) {
      background-color: #AFC0DB;
    }
    &:nth-child(3) {
      background-color: #C8C5D0;
    }
    &:nth-child(4) {
      background-color: #B1D2E2;
    }
  }
}
.left-menu {
  width: 210px;
  background-color: #1935DE;
  padding-top: 18px;
  box-sizing: border-box;
  &-cell {
    height: 60px;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    box-sizing: border-box;
    padding-left: 10px;
    &-box {
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: 30px;
      box-sizing: border-box;
      cursor: pointer;
    }
  }
  .isActive {
    background-color: #fff;
    color: #1935DE;
  }
}
.menu-item-card {
  display: inline-block;
  width: 210px;
  height: 140px;
  background-color: #F7F8FE;
  vertical-align: middle;
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 20px;
  box-sizing: border-box;
}
</style>
